
  import { computed, defineComponent, ref } from 'vue';
  import { useI18n } from 'vue-i18n/index';
  import { useStore } from 'vuex';
  import { useAbility } from '@casl/vue';
  import Datatable from '@/components/kt-datatable/KTDatatable.vue';
  import { Actions } from '@/store/enums/StoreEnums';
  import Swal from 'sweetalert2/dist/sweetalert2.js';

  export default defineComponent({
    name: 'properties-listing',
    emit: ['list-changed', 'show-edit-property-modal'],
    props: {
      createdAuction: { type: Object, default: null },
    },
    components: {
      Datatable,
    },
    async setup(props, { emit }) {
      const { t, te } = useI18n();
      const { can } = useAbility();
      const store = useStore();

      const translate = (text) => {
        if (te(text)) {
          return t(text);
        } else {
          return text;
        }
      };

      const tableHeader = ref([
        {
          name: translate('id'),
          key: 'index',
          sortable: false,
        },
        {
          name: translate('propertyTypeText'),
          key: 'propertyType',
          sortable: false,
        },
        {
          name: translate('area'),
          key: 'areaName',
          sortable: false,
        },
        {
          name: translate('status'),
          key: 'auctionStatus',
          sortable: false,
        },
        {
          name: translate('createdAt'),
          key: 'createdAt',
          sortable: true,
        },
        {
          name: translate('status'),
          key: 'isActive',
          sortable: true,
        },
        {
          name: translate('startingPrice'),
          key: 'startingPrice',
          sortable: true,
        },
        {
          name: translate('actions'),
          key: 'actions',
          sortable: false,
        },
      ]);
      const tableData = computed(() => store.getters.auctionPropertiesList);

      const toggleStatusProperty = async (property) => {
        const deletedMessage = property.isActive
          ? 'confirmInActive'
          : 'confirmActive';
        const result = await Swal.fire({
          title: translate('areYouSureQ'),
          icon: 'warning',
          text: translate(deletedMessage),
          showCancelButton: true,
          buttonsStyling: false,
          confirmButtonText: translate('confirm'),
          cancelButtonText: translate('cancel'),
          customClass: {
            confirmButton: 'btn fw-bold btn-warning',
            cancelButton: 'btn btn-light',
          },
        });

        if (result.value) {
          await store.dispatch(Actions.DELETE_AUCTION_PROPERTY, property.id);
          const [errorName] = Object.keys(store.getters.getErrors);
          const error = store.getters.getErrors[errorName];
          if (error) {
            Swal.fire({
              text: translate(error[0]),
              icon: 'error',
              buttonsStyling: false,
              confirmButtonText: translate('tryAgainExcl'),
              customClass: {
                confirmButton: 'btn fw-bold btn-light-danger',
              },
            });
          } else {
            const successMsg = property.isActive
              ? 'SUCCESSFULLY_INACTIVATED'
              : 'SUCCESSFULLY_ACTIVEATED';
            Swal.fire({
              text: translate(successMsg),
              icon: 'success',
              buttonsStyling: false,
              confirmButtonText: translate('ok'),
              customClass: {
                confirmButton: 'btn btn-light-primary',
              },
            });
          }
          emit('list-changed');
        }
      };

      const editOption = (id) => {
        emit('show-edit-property-modal', id);
      };

      store.dispatch(Actions.REMOVE_BODY_CLASSNAME, 'page-loading-over');

      return {
        editOption,
        tableHeader,
        tableData,
        translate,
        can,
        toggleStatusProperty,
      };
    },
  });
