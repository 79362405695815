import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "card mb-5 mb-xl-10"
}
const _hoisted_2 = { class: "card-header border-0" }
const _hoisted_3 = { class: "card-title m-0" }
const _hoisted_4 = { class: "fw-bolder m-0" }
const _hoisted_5 = { class: "card-toolbar" }
const _hoisted_6 = ["disabled"]
const _hoisted_7 = { class: "svg-icon svg-icon-3" }
const _hoisted_8 = {
  id: "kt_auction_create_properties",
  class: "collapse show"
}
const _hoisted_9 = { class: "card-body border-top p-9" }
const _hoisted_10 = {
  key: 0,
  class: "notice d-flex bg-light-primary rounded border-primary border border-dashed mb-9 p-6"
}
const _hoisted_11 = { class: "svg-icon svg-icon-2tx svg-icon-primary me-4" }
const _hoisted_12 = { class: "d-flex flex-stack flex-grow-1" }
const _hoisted_13 = { class: "fw-bold" }
const _hoisted_14 = { class: "fs-6 text-gray-600" }
const _hoisted_15 = {
  key: 0,
  class: "badge badge-light-success"
}
const _hoisted_16 = {
  key: 1,
  class: "badge badge-light-danger"
}
const _hoisted_17 = ["onClick"]
const _hoisted_18 = { class: "svg-icon svg-icon-3" }
const _hoisted_19 = ["onClick"]
const _hoisted_20 = { class: "svg-icon svg-icon-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_Datatable = _resolveComponent("Datatable")!

  return (_ctx.can('create', 'auctions'))
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("h3", _hoisted_4, _toDisplayString(_ctx.translate('auctionProperties')), 1)
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("button", {
              type: "button",
              class: "btn btn-sm btn-flex btn-light-primary",
              "data-bs-toggle": "modal",
              disabled: !_ctx.createdAuction,
              "data-bs-target": "#kt_modal_new_property"
            }, [
              _createElementVNode("span", _hoisted_7, [
                _createVNode(_component_inline_svg, { src: "/media/icons/duotune/general/gen035.svg" })
              ]),
              _createTextVNode(" " + _toDisplayString(_ctx.translate('addNewProperty')), 1)
            ], 8, _hoisted_6)
          ])
        ]),
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("div", _hoisted_9, [
            (!_ctx.createdAuction)
              ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                  _createElementVNode("span", _hoisted_11, [
                    _createVNode(_component_inline_svg, { src: "/media/icons/duotune/general/gen045.svg" })
                  ]),
                  _createElementVNode("div", _hoisted_12, [
                    _createElementVNode("div", _hoisted_13, [
                      _createElementVNode("div", _hoisted_14, _toDisplayString(_ctx.translate('savingAuctionPropertiesNote')), 1)
                    ])
                  ])
                ]))
              : _createCommentVNode("", true),
            _createVNode(_component_Datatable, {
              "table-header": _ctx.tableHeader,
              "table-data": _ctx.tableData,
              "rows-per-page": 1000,
              "enable-items-per-page-dropdown": false
            }, {
              "cell-index": _withCtx(({ row: property }) => [
                _createTextVNode(_toDisplayString(property.index), 1)
              ]),
              "cell-propertyType": _withCtx(({ row: property }) => [
                _createTextVNode(_toDisplayString(property.propertyType), 1)
              ]),
              "cell-areaName": _withCtx(({ row: property }) => [
                _createTextVNode(_toDisplayString(property.areaName), 1)
              ]),
              "cell-auctionStatus": _withCtx(({ row: property }) => [
                _createTextVNode(_toDisplayString(property.auctionStatus), 1)
              ]),
              "cell-startingPrice": _withCtx(({ row: property }) => [
                _createTextVNode(_toDisplayString(property.startingPrice), 1)
              ]),
              "cell-createdAt": _withCtx(({ row: property }) => [
                _createTextVNode(_toDisplayString(property.createdAt), 1)
              ]),
              "cell-isActive": _withCtx(({ row: property }) => [
                (property.isActive)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_15, _toDisplayString(_ctx.translate('active')), 1))
                  : (_openBlock(), _createElementBlock("span", _hoisted_16, _toDisplayString(_ctx.translate('inactive')), 1))
              ]),
              "cell-actions": _withCtx(({ row: property }) => [
                _createElementVNode("a", {
                  "data-bs-toggle": "modal",
                  "data-bs-target": "#kt_modal_edit_property",
                  href: "#",
                  class: "btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-2",
                  onClick: _withModifiers(($event: any) => (_ctx.editOption(property.id)), ["prevent"])
                }, [
                  _createElementVNode("span", _hoisted_18, [
                    _createVNode(_component_inline_svg, { src: "/media/icons/duotune/art/art005.svg" })
                  ])
                ], 8, _hoisted_17),
                _createElementVNode("a", {
                  href: "#",
                  class: "btn btn-icon btn-bg-light btn-active-color-primary btn-sm",
                  onClick: _withModifiers(($event: any) => (_ctx.toggleStatusProperty(property)), ["prevent"])
                }, [
                  _createElementVNode("span", _hoisted_20, [
                    _createVNode(_component_inline_svg, { src: "/media/icons/duotune/general/gen019.svg" })
                  ])
                ], 8, _hoisted_19)
              ]),
              _: 1
            }, 8, ["table-header", "table-data"])
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}